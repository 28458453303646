import React, { useState, useEffect } from "react";
import "../../../css/main.6120d571.css";
import axios from "axios";
import BASE_URL from "../../../BASE_URL";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import Swal from "sweetalert2";
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast } from "react-toastify";
function Clients() {
  //

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [blocked, setBlocked] = useState(false);
  const [checked, setChecked] = useState(false);
  const [singleChecked, setSingleChecked] = useState(false);

  const removedFun = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${BASE_URL}user/user/deleteById/${id}`)
          .then(async(result) => {
            console.log(result.data.status);

            if (result.data.status === "success") {
             
            
                
            

              Swal.fire("Deleted!", "Your file has been deleted.", "success");

              const response = await axios.get(`https://eccom.ashhkaro.com/ecommerce/users/get`);

              console.log(response.data);
              setUsers(response.data.data);
              
        
       
            }
          });
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://eccom.ashhkaro.com/ecommerce/users/get`);
        console.log(response.data);
        setUsers(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleCheckAll = () => {
    setChecked(!checked);
  };


  const [toggle, setToggle] = useState({
    id: "",
  });

  return (
    <div className={"login-activity"}>
      <div className="container-fluid p-5">
        {/* <Loader />  */}
        <div className="page-titles">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/react/demo/table-bootstrap-basic">Ashkaro</a>
            </li>
            <li className="breadcrumb-item active">
              <a href="/react/demo/table-bootstrap-basic">Clients</a>
            </li>
          </ol>
        </div>
        <div className="row">
          <div className="col-lg-12 all_col">
            <div className="card">
              <div className="card-header">
                <div className="card-title h5">Clients</div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr onClick={() => {}}>
                        <th className="width50">
                          <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                            <input
                              onClick={handleCheckAll}
                              type="checkbox"
                              className="custom-control-input"
                              id="checkAll"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="checkAll"
                            />
                          </div>
                        </th>
                        <th>
                          <strong>NAME</strong>
                        </th>
                        <th>
                          <strong>Email</strong>
                        </th>

                        <th>
                          <strong>Phone</strong>
                        </th>
                        
                        <th>
                          <strong>View Details</strong>
                        </th>
                         <th>
                          <strong>Action</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user, index) => (
                        <tr>
                          <td>
                            <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                              {checked ? (
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={`customCheckBox${index}`}
                                  checked={true}
                                  onClick={() => setSingleChecked(index)}
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={`customCheckBox${index}`}
                                  onClick={() => setSingleChecked(index)}
                                />
                              )}
                              <label
                                className="custom-control-label"
                                htmlFor={`customCheckBox${index}`}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="w-space-no">{user.firstName}</span>
                            </div>
                          </td>
                          <td>{user.email}</td>

                          <td>{user.phoneNumber}</td>
                         
                          <td>
                            <div className="">
                            <Link
                              to={`/userProfile/${user?.id}`}
                              className={"button"}
                            >
                              View Details
                            </Link>
                            </div>
                            
                          </td>
                           <td>
                                <RiDeleteBin6Line onClick={()=>removedFun(user?.id)} size={20} color="red" />
                              </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Clients;
