import React, { useState, useEffect } from "react";
import "../../../css/main.6120d571.css";
import axios from "axios";
import BASE_URL from "../../../BASE_URL";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast } from "react-toastify";

const Captains = (props) => {
  const [captains, setCaptains] = useState([]);
  const [checked, setChecked] = useState(false);
  const [singleChecked, setSingleChecked] = useState(false);

  useEffect(() => {
    const fetchCaptains = async () => {
      try {
        const response = await axios.get(`${BASE_URL}rider/rider/getAll`);
        setCaptains(response.data.data);
        console.log(response)
      } catch (error) {
        console.log(error);
      }
    };
    fetchCaptains();
  }, []);

  const removedFun = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${BASE_URL}rider/rider/deleteById/${id}`)
          .then(async(result) => {
            console.log(result.data.status);

            if (result.data.status === "success") {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");

              const response = await axios.get(`${BASE_URL}rider/rider/getAll`);
              setCaptains(response.data.data);
            }
          });
      }
    });
  };

  const handleCheckAll = () => {
    setChecked(!checked);
  };

  const handleSingleCheck = () => {
    setSingleChecked(true);
  };

  // Toggle status (available / pending)
  const toggleStatus = (captain) => {
    console.log(captain)
    const newStatus = captain.status === 'available' ? 'pending' : 'available'; // Toggle the status
    const params = { "documentId": captain.id, "stat": newStatus };

    axios.put(`${BASE_URL}rider/riderDocuments/docUpdateStatus`, params)

      .then((res) => {
        console.log(res.data)
        if (res.data.status === 'success') {
          // Update the captain status in the UI
          toast.success('Captain status updated!');
          setCaptains(captains.map((capt) => 
            capt.id === captain.id ? { ...capt, state: newStatus } : capt
          ));
        } else {
          toast.error('Error updating captain status!');
        }
      });
  };

  return (
    <div className={"login-activity"}>
      <div className="container-fluid p-5">
        <div className="page-titles">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/react/demo/table-bootstrap-basic">Ashkaro</a>
            </li>
            <li className="breadcrumb-item active">
              <a href="/react/demo/table-bootstrap-basic">Captains</a>
            </li>
          </ol>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <div className="card-title h5">Captains</div>
              </div>
              <div className="card-body">
                <div className="table-responsive table-responsive-lg">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="width50">
                          <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                            <input
                              onClick={handleCheckAll}
                              type="checkbox"
                              className="custom-control-input"
                              id="checkAll"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="checkAll"
                            />
                          </div>
                        </th>
                        <th><strong>NAME</strong></th>
                        <th><strong>STATE</strong></th>
                        <th><strong>Phone</strong></th>
                        <th><strong>View Details</strong></th>
                        <th><strong>Action</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      {captains.map((captain, index) => (
                        <tr key={captain.id}>
                          <td>
                            <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`customCheckBox${index}`}
                                onClick={() => setSingleChecked(index)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`customCheckBox${index}`}
                              />
                            </div>
                          </td>
                          <td>{captain.firstName + captain.lastName}</td>
                          <td>{captain.state}</td>
                          <td>{captain.phone}</td>
                          
                          <td>
                            <Link to={`/captainsProfile/${captain?.id}`} className={"button"}>
                              View Details
                            </Link>
                          </td>
                          <td>
                            <RiDeleteBin6Line onClick={() => removedFun(captain?.id)} size={20} color="red" />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Captains;
